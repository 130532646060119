import React from 'react'
import styled from 'styled-components'
import { Flex } from '@pancakeswap/uikit'
import Footer from 'components/Menu/Footer'
import SubNav from 'components/Menu/SubNav'

const StyledPage = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 width: 100%;
 padding: 16px;
 padding-bottom: 0;
 min-height: calc(100vh - 64px);
 background: ${({ theme }) => theme.colors.gradients.bubblegum};
 background:
  radial-gradient(circle, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 70%),
  url('/images/background/pipiswap.webp') center/cover no-repeat fixed;

 ${({ theme }) => theme.mediaQueries.sm} {

 padding: 32px;
 padding-bottom: 0;
 }

  

 ${({ theme }) => theme.mediaQueries.lg} {
 padding-top: 64px;
 min-height: calc(100vh - 64px);
 }
`

const Page: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <StyledPage {...props}>
      <SubNav /> {children}
      <Flex flexGrow={1} />
      <Footer />
    </StyledPage>
  )
}

export default Page

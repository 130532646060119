import { useState, useEffect, useRef } from 'react';
import { geckoService } from './gecko-terminal.api';

type Props = {
  address: string,
  network: 'bsc' | 'core';
};

export const useGeckoTerminalPrice = ({ address, network }: Props) => {
  const [tokenInfo, setTokenInfo] = useState<{ name: string; price: number | null } | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const prevAddressesRef = useRef<string[]>([]);
  const prevNetworkRef = useRef<string>('');

  useEffect(() => {
    const fetchTokenPrice = async () => {
      setLoading(true);
      setError(null);

      try {
        const data = await geckoService.getPrices([address], network);



        if (data?.data) {
          setTokenInfo({
            name: network,
            price: data?.data?.attributes?.token_prices[address]
          });
        } else {
          setError('Token data not found');
        }
      } catch (err: any) {
        setError(err.message || 'Unknown error');
      } finally {
        setLoading(false);
      }
    };


    if (
      !arraysEqual(prevAddressesRef.current, [address]) ||
      prevNetworkRef.current !== network
    ) {
      fetchTokenPrice();
      prevAddressesRef.current = [address];
      prevNetworkRef.current = network;
    }
  }, [address, network]);


  const arraysEqual = (arr1: string[], arr2: string[]): boolean => {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((val, index) => val === arr2[index]);
  };

  return {
    tokenInfo,
    loading,
    error,
  };
};

class TokenPriceService {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  async getPrices(addresses: string[], network?: string): Promise<any> {
    try {

      const endpoint = `/simple/networks/${network ?? 'bsc'}/token_price/${addresses.join(',')}`;
      const url = `${this.baseUrl}${endpoint}`;

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching prices: ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error: any) {
      throw new Error(`Error getting prices: ${error.message}`);
    }
  }
}


export const geckoService = new TokenPriceService('https://api.geckoterminal.com/api/v2');
